import React from "react"

// import Icons from "./Icons"
import links from "./content"

import * as S from "./styled"

const SocialLinks = props => {
  const { footer, contact } = props

  return (
    <S.SocialLinksList footer={footer} contact={contact}>
      {links.map((link, i) => {
        const Icon = link.icon

        return (
          <S.SocialLinksItem key={i}>
            <S.SocialLinksLink
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.IconWrapper>
                <Icon size={22} />
              </S.IconWrapper>
            </S.SocialLinksLink>
          </S.SocialLinksItem>
        )
      })}
    </S.SocialLinksList>
  )
}

export default SocialLinks
