import styled, { css } from "styled-components"

import { sizes, breakpoints } from "../../styles/breakpoints"

export const SocialLinksList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  height: 100%;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    height: auto;
  }

  ${props =>
    props.footer &&
    css`
      height: auto;
      justify-content: center;

      li a div {
        color: var(--color-grayscale4);

        &:hover {
          color: var(--color-white);
          background-color: transparent;
        }
      }
    `}

  ${props =>
    props.contact &&
    css`
      height: auto;
      justify-content: flex-start;

      li a div {
        color: var(--color-primary);

        &:hover {
          opacity: 0.8;
          background-color: transparent;
        }
      }
    `}
`

export const SocialLinksItem = styled.li`
  height: 100%;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    height: auto;
  }
`

export const SocialLinksLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    height: auto;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  height: 100%;
  color: var(--color-grayscale4);
  transition: color ease 0.2s, background-color ease 0.2s, opacity ease 0.2s;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-primary);
  }

  @media ${breakpoints.lessThan(sizes.desktop)} {
    height: auto;
    &:hover {
      background-color: transparent;
      color: var(--color-white);
    }
  }

  @media ${breakpoints.lessThan(sizes.phone)} {
    padding: 0 2px;
  }
`
