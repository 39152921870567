import styled, { css } from "styled-components"

import { Container as ContainerWrapper } from "../../styles/container"

import { sizes, breakpoints } from "../../styles/breakpoints"

export const Header = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 65px;
  background-color: var(--color-primary);
  z-index: 900;

  @media ${breakpoints.lessThan(sizes.phone)} {
    height: 60px;
  }

  .brand {
    z-index: 1000;
    width: 80px;
    height: 50px;

    .img-wrapper {
      width: 100%;
      height: 100%;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }
`

export const Container = styled(ContainerWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const MenuBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    opacity: 0;
    z-index: 500;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 110px 40px 60px;
    transform: translateY(-999px);
    background-color: var(--color-primary);
    transition: transform linear 0s 0.4s, opacity ease-in-out 0.3s;

    ${props =>
      props.isActive &&
      css`
        opacity: 1;
        transform: translateY(0);
        transition: transform 0s, opacity ease-in-out 0.3s;
      `}
  }

  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding-top: 100px;
  }

  @media ${breakpoints.lessThan(sizes.smaller)} {
    padding-top: 70px;
  }
`

export const MenuNav = styled.nav`
  /* margin-right: 60px; */
  /* @media ${breakpoints.lessThan(sizes.widescreen)} {
    margin-right: 40px;
  } */
  @media ${breakpoints.lessThan(sizes.desktop)} {
    /* margin-right: 0; */
    margin-bottom: 40px;
  }
`

export const MenuList = styled.ul`
  display: flex;
  align-items: center;
  gap: 15px;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    flex-direction: column;
    justify-content: center;
    gap: 35px;
  }
  @media ${breakpoints.lessThan(sizes.desktop)} {
    gap: 25px;
  }
  @media ${breakpoints.lessThan(sizes.desktop)} {
    gap: 20px;
  }
`

export const MenuItem = styled.li`
  text-align: center;
  a {
    color: var(--color-grayscale4);
    text-align: center;

    &:hover,
    &.active {
      color: var(--color-white);
    }

    @media ${breakpoints.lessThan(sizes.desktop)} {
      font-size: 2rem;
    }
    @media ${breakpoints.lessThan(sizes.tablet)} {
      font-size: 1.7rem;
    }
    @media ${breakpoints.lessThan(sizes.phone)} {
      font-size: 1.6rem;
    }
  }
`
