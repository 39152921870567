import styled, { css } from "styled-components"

import { sizes, breakpoints } from "./breakpoints"

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: ${sizes.widescreen};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  @media ${breakpoints.lessThan(sizes.large)} {
    max-width: ${sizes.widescreen};
  }
  @media ${breakpoints.lessThan(sizes.widescreen)} {
    padding: 0 30px;
    max-width: ${sizes.desktop};
  }
  @media ${breakpoints.lessThan(sizes.desktop)} {
    max-width: ${sizes.tablet};
  }
  @media ${breakpoints.lessThan(sizes.tablet)} {
    max-width: ${sizes.phone};
  }
  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding: 0 20px;
  }
`
