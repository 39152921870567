// import { Email } from "@styled-icons/material/Email"
import { FacebookSquare } from "@styled-icons/boxicons-logos/FacebookSquare"
import { Instagram } from "@styled-icons/remix-fill/Instagram"
import { Twitter } from "@styled-icons/boxicons-logos/Twitter"
import { LinkedinSquare } from "@styled-icons/boxicons-logos/LinkedinSquare"
import { PlayStore } from "@styled-icons/boxicons-logos/PlayStore"
import { Apple } from "@styled-icons/boxicons-logos/Apple"

const links = [
  // {
  //   label: "Email",
  //   url: "mailto:contato@ruymolina.adv.br",
  //   icon: Email,
  // },
  {
    label: "Facebook",
    url: "https://facebook.com/advocaciaruymolina",
    icon: FacebookSquare,
  },
  {
    label: "Instagram",
    url: "https://instagram.com/ruymolinaadvocacia",
    icon: Instagram,
    // social: true,
  },
  {
    label: "Twitter",
    url: "https://twitter.com/ruymolinaadv",
    icon: Twitter,
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/ruymolinaadv",
    icon: LinkedinSquare,
  },
  {
    label: "Play Store",
    url: "https://play.google.com/store/apps/details?id=com.ruymolina1.app",
    icon: PlayStore,
  },
  {
    label: "App Store",
    url: "https://apps.apple.com/br/app/ruy-molina-advocacia/id1597802552",
    icon: Apple,
  },
]

export default links
