import styled from "styled-components"

// import { sizes, breakpoints } from "../../styles/breakpoints"

export const Footer = styled.footer`
  /* margin-top: 40px; */
  text-align: center;
  padding: 20px 0 15px;
  background-color: var(--color-primary);
`

export const FooterBottom = styled.div`
  .privacy-policy-link {
    display: inline-block;
    text-decoration: none;
    color: var(--color-white);
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.7rem;
    margin: 20px 0 5px;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const FooterSignature = styled.small`
  display: block;
  margin-top: 20px;
  color: var(--color-white);
  font-size: 0.95rem;
`
