import React, { useState } from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

import SocialLinks from "../SocialLinks"
import Hamburger from "../Hamburger"

import * as S from "./styled"

const Header = ({ siteTitle }) => {
  const [isActive, setActive] = useState(false)

  const handleHamburgerToggle = () => setActive(!isActive)

  return (
    <S.Header>
      <S.Container>
        <h1 className="sr-only">{siteTitle}</h1>

        <AniLink
          className="brand"
          cover
          to="/"
          direction="left"
          duration={1}
          bg="#EAEAEA"
        >
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../images/rm-icon.svg"
            objectPosition="left"
            objectFit="cover"
            placeholder="none"
            alt="Logo Ruy Molina"
          />
        </AniLink>

        <S.MenuBox isActive={isActive}>
          <S.MenuNav>
            <h2 className="sr-only">Menu</h2>
            <S.MenuList>
              <S.MenuItem>
                <AniLink
                  activeClassName="active"
                  cover
                  to="/"
                  direction="left"
                  duration={1}
                  bg="#EAEAEA"
                  title="Home"
                >
                  Home
                </AniLink>
              </S.MenuItem>
              <S.MenuItem>
                <AniLink
                  activeClassName="active"
                  cover
                  to="/sobre"
                  direction="right"
                  duration={0.8}
                  bg="#EAEAEA"
                  title="Quem somos"
                >
                  Quem somos
                </AniLink>
              </S.MenuItem>
              <S.MenuItem>
                <AniLink
                  cover
                  direction="bottom"
                  bg="#EAEAEA"
                  duration={0.8}
                  to="/unidades"
                  activeClassName="active"
                  title="Unidades"
                >
                  Unidades
                </AniLink>
              </S.MenuItem>
              <S.MenuItem>
                <AniLink
                  cover
                  direction="bottom"
                  bg="#EAEAEA"
                  duration={0.8}
                  to="/artigos"
                  activeClassName="active"
                  title="Artigos"
                >
                  Artigos
                </AniLink>
              </S.MenuItem>
              <S.MenuItem>
                <AniLink
                  cover
                  direction="right"
                  bg="#EAEAEA"
                  duration={0.8}
                  to="/cadastre-um-processo"
                  activeClassName="active"
                  title="Cadastre um processo"
                >
                  Cadastre um processo
                </AniLink>
              </S.MenuItem>
              <S.MenuItem>
                <AniLink
                  cover
                  direction="right"
                  bg="#EAEAEA"
                  duration={0.8}
                  to="/contato"
                  activeClassName="active"
                  title="Contato"
                >
                  Contato
                </AniLink>
              </S.MenuItem>
            </S.MenuList>
          </S.MenuNav>
        </S.MenuBox>

        <SocialLinks />

        <Hamburger
          className="hamburger hamburger--squeeze"
          isActive={isActive}
          handleToggle={handleHamburgerToggle}
        />
      </S.Container>
    </S.Header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
