import styled from "styled-components"

import { sizes, breakpoints } from "../../styles/breakpoints"

export const ButtonHamburger = styled.button`
  z-index: 1000;
  @media ${breakpoints.greaterThan(sizes.desktop)} {
    display: none;
  }
  .hamburger-box {
    .hamburger-inner {
      background-color: var(--color-white);
      &::before,
      &::after {
        background-color: var(--color-white);
      }
    }
  }
`
