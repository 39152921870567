import * as React from "react"

import { Container } from "../../styles/container"
import SocialLinks from "../SocialLinks"

import * as S from "./styled"

const Footer = ({ children }) => (
  <S.Footer>
    <Container>
      {children}

      <S.FooterBottom>
        <SocialLinks footer />

        <a
          className="privacy-policy-link"
          href="https://www.ruymolina.adv.br/politica-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
          title="Política de privacidade"
        >
          Política de privacidade
        </a>

        <S.FooterSignature>
          © {new Date().getFullYear()} Ruy Molina Advocacia
        </S.FooterSignature>
      </S.FooterBottom>
    </Container>
  </S.Footer>
)

export default Footer
