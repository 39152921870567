import { createGlobalStyle } from "styled-components"

const GlobalColors = createGlobalStyle`
  /* Css Variables */
  :root {
  --color-transparent: transparent;
  --color-white: #fff;
  --color-black: #222;
  --color-grayscale2: #FAFAFA;
  --color-grayscale3: #EAEAEA;
  --color-grayscale4: #B5B5B5;
  --color-grayscale5: #797979;
  --color-grayscale6: #3B3B3B;
  --color-grayscale7: #1B1B1B;
  --color-primary: #393E46;
  --color-secondary: #D0A144;
  --color-ternary: #FAFAFA;
  --color-success: #3AD19D;
  --color-danger: #DC4141;
  --color-info: #4C9AFF;
  --color-warning: #F7B500;
  --color-whatsapp: #25D366;
  --color-whatsapp2: #128C7E;
  }
`
export default GlobalColors
