import * as React from "react"

import "./vendor.css"
import * as S from "./styled"

const Hamburger = props => {
  const handleToggle = props.handleToggle
  const isActive = props.isActive
  const className = props.className || "hamburger hamburger--collapse"

  return (
    <S.ButtonHamburger
      className={isActive ? `${className} is-active` : className}
      type="button"
      onClick={handleToggle}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </S.ButtonHamburger>
  )
}

export default Hamburger
